import { oauth } from '../OAuth';
import { restCall } from './api';

const ipRestrictionsEndpoint = process.env.REACT_APP_IPRESTRICTIONS_ENDPOINT;
const ipRestrictionsGetByOrg = process.env.REACT_APP_IPRESTRICTIONS_GET_BY_ORG;
const organizationEndpoint = process.env.REACT_APP_ORGANIZATION_ENDPOINT;

export const getIpRestrictionsByOrg = async ({ orgId }) => {
    const ipRestrictionsByOrgUrl = `${ipRestrictionsGetByOrg}/${orgId}`;

    const response = await restCall(ipRestrictionsByOrgUrl, {
        withCredentials: true,
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            'Content-Type': 'application/json',
        },
        method: 'GET',
    });

    return response;
};

export const updateIpRestrictions = async ({ orgId, data }) => {
    const orgRef = `${organizationEndpoint}/${orgId}`;
    let method = 'PUT';
    let url = ipRestrictionsEndpoint;

    // check if ip restrictions already exist
    const response = await getIpRestrictionsByOrg({ orgId });

    // if not, create a new one
    if (!response) {
        method = 'POST';
    } else {
        url += `/${response.id}`;
    }

    data = {
        ...data,
        organization: orgRef,
    };

    const mergedParams = {
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${oauth.bearerToken}`,
            'Content-Type': 'application/json',
        },
        method,
        withCredentials: true,
    };

    return await restCall(url, mergedParams);
};
