import React, { useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router-dom';
import './CenterAuthenticationForm.scss';
import { addAPIUserDispatch } from '../../redux/actions';
import { replaceText } from '../../helper';
import {
    getPasswordPolicyForDispatchCenter,
    updatePasswordPolicyConfiguration,
} from '../../api/passwordPolicyAPI';
import {
    DispatchCenter,
    Match,
    MatchParams,
    currentUserOrganization,
} from '../../types';
import { DEFAULT_TIMEOUT, IP_RESTRICTION_ADDRESS_LIMIT } from '../../config';
import { getAzureStatus } from '../../api/api';
import Add from '../icons/Add';
import Remove from '../icons/Remove';
import ToggleSwitch from '../global/ToggleSwitch';
import {
    getIpRestrictionsByOrg,
    updateIpRestrictions,
} from '../../api/ipRestrictionsAPI';

interface Props {
    apiToken: string;
    currentUser: {
        organization: currentUserOrganization;
    };
    texts: {
        texts: [key: string];
    };
    dispatchCenters: {
        centers: Array<DispatchCenter>;
    };
}

type CenterAuthenticationProps = ConnectedProps<typeof connector> &
    Props & {
        match: Match;
    };

const CenterAuthenticationComponent: React.FC<CenterAuthenticationProps> = ({
    texts,
    apiToken,
    currentUserOrganization,
    centers,
}) => {
    const [hasMinimumLength, setHasMinimumLength] = useState(false);
    const [hasMixedCase, setHasMixedCase] = useState(false);
    const [hasSpecialCharacters, setHasSpecialCharacters] = useState(false);
    const [hasNumerics, setHasNumerics] = useState(false);
    const [compareLastPassword, setCompareLastPassword] = useState(false);
    const [passwordMinimumLength, setPasswordMinimumLength] = useState(8);
    const [passwordExpirationDate, setPasswordExpirationDate] = useState(0);
    const [policiesSaved, setPoliciesSaved] = useState(false);

    const [ipRestrictionsIsActivated, setIpRestrictionsIsActivated] =
        useState(false);
    const [subnetMaskInput, setSubnetMaskInput] = useState('');
    const [ipAddressInput, setIpAddressInput] = useState('');
    const [ipRestrictionsSaved, setIpRestrictionsSaved] = useState({});
    const [ipHasError, setIpHasError] = useState(false);

    const [isAzureSystem, setIsAzureSystem] = useState(false);

    const params = useParams<MatchParams>();
    const orgId = params.id;

    const currentCenter = centers.filter(
        center => Number(orgId) === center.id
    )[0];

    const ipFormatRegex =
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    const subnetRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|)$/;

    let areaRef = useRef<HTMLTextAreaElement | null>(null);
    const ipRestrictionClasses = `ip-restriction ${
        ipHasError ? 'has-error' : ''
    }`;

    const addNewAddressClasses = `${
        Object.entries(ipRestrictionsSaved).length >=
        IP_RESTRICTION_ADDRESS_LIMIT
            ? 'ip-restriction--adding-is-disabled'
            : ''
    }`;

    const setPolicies = (policy: string) => {
        switch (true) {
            case policy === 'MIXED_CASE':
                setHasMixedCase(true);
                break;
            case policy === 'CHAR_COUNT':
                setHasMinimumLength(true);
                break;
            case policy === 'SPECIAL_CHARS':
                setHasSpecialCharacters(true);
                break;
            case policy === 'NUMERICS':
                setHasNumerics(true);
                break;
            case policy === 'LAST_PASSWORD':
                setCompareLastPassword(true);
                break;
            default:
                break;
        }
    };

    const getUpdatedPolicies = () => {
        let policiesToSave = [];
        if (hasMinimumLength) {
            policiesToSave = [...policiesToSave, 'CHAR_COUNT'];
        }
        if (hasMixedCase) {
            policiesToSave = [...policiesToSave, 'MIXED_CASE'];
        }
        if (hasSpecialCharacters) {
            policiesToSave = [...policiesToSave, 'SPECIAL_CHARS'];
        }
        if (hasNumerics) {
            policiesToSave = [...policiesToSave, 'NUMERICS'];
        }
        if (compareLastPassword) {
            policiesToSave = [...policiesToSave, 'LAST_PASSWORD'];
        }

        return policiesToSave;
    };

    const validatePasswordExpiry = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (Number(e.currentTarget.value) > 99) {
            return;
        } else if (Number(e.currentTarget.value) < 0) {
            setPasswordExpirationDate(0);
        } else {
            setPasswordExpirationDate(Number(e.currentTarget.value));
        }
    };

    const validateMinimumLength = (e: any) => {
        if (Number(e.currentTarget.value) > 30) {
            return;
        } else if (Number(e.currentTarget.value) < 8) {
            setPasswordMinimumLength(8);
        } else {
            setPasswordMinimumLength(e.currentTarget.value);
        }
    };

    const savePolicies = async e => {
        e.preventDefault();
        let enabledPolicies = getUpdatedPolicies();
        await updatePasswordPolicyConfiguration({
            addOnId: Number(currentCenter.id),
            enabledPolicies: enabledPolicies,
            minimumPasswordLength: passwordMinimumLength,
            passwordExpirationLength: passwordExpirationDate,
        });
        setPoliciesSaved(true);
        setTimeout(() => {
            setPoliciesSaved(false);
        }, DEFAULT_TIMEOUT);
    };

    const addApiUser = event => {
        event.preventDefault();
        addAPIUserDispatch();
    };

    const copyToClipboard = event => {
        event.preventDefault();
        if (areaRef?.current) {
            navigator.clipboard
                .writeText(areaRef.current.textContent)
                .then(() => {
                    window.alert(replaceText(texts, 'token.copied'));
                })
                .catch(err => {
                    console.log(err);
                });
        }
    };

    const readToken = apiToken => {
        if (apiToken === '') return { exp: '', iat: '' };
        const parse = JSON.parse(atob(apiToken.split('.')[1]));
        const exp = new Date(parse.exp * 1000).toLocaleDateString(
            navigator.language,
            {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            }
        );
        const iat = new Date(parse.iat * 1000).toLocaleDateString(
            navigator.language,
            {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
            }
        );
        return { exp, iat };
    };

    const { exp, iat } = readToken(apiToken);
    const userOrgId = `${currentUserOrganization.id}`;

    const updateIpAddress = e => {
        setIpAddressInput(e.target.value);
    };

    const validateIpRestrictionInput = () => {
        if (ipFormatRegex.test(ipAddressInput)) {
            setIpHasError(false);
            return false;
        } else {
            setIpHasError(true);
            return true;
        }
    };

    const updateSubnetMask = e => {
        if (subnetRegex.test(e.target.value)) {
            setSubnetMaskInput(e.target.value);
        }
    };

    const saveIpRestriction = async () => {
        if (
            Object.entries(ipRestrictionsSaved).length >=
            IP_RESTRICTION_ADDRESS_LIMIT
        ) {
            return;
        }

        if (validateIpRestrictionInput()) {
            return;
        }

        if (
            typeof currentCenter.id === 'number' &&
            !Number.isNaN(currentCenter.id)
        ) {
            const data = {
                enabled: ipRestrictionsIsActivated,
                ipAdressSubnetMaskMap: {
                    ...ipRestrictionsSaved,
                    [ipAddressInput]: subnetMaskInput,
                },
            };
            await updateIpRestrictions({
                orgId: currentCenter.id,
                data,
            });

            setIpRestrictionsSaved(data.ipAdressSubnetMaskMap);
            setIpRestrictionsIsActivated(data.enabled);
            setIpAddressInput('');
            setSubnetMaskInput('');
        }
    };

    const deleteAddress = async key2Delete => {
        const filteredAddresses = Object.entries(ipRestrictionsSaved).filter(
            (entry, key) => key !== key2Delete
        );

        const data = {
            enabled: ipRestrictionsIsActivated,
            ipAdressSubnetMaskMap: {
                ...Object.fromEntries(filteredAddresses),
            },
        };

        await updateIpRestrictions({
            orgId: currentCenter.id,
            data,
        });

        setIpRestrictionsSaved(data.ipAdressSubnetMaskMap);
        setIpRestrictionsIsActivated(data.enabled);
    };

    const toggleIpRestrictions = async () => {
        const data = {
            enabled: !ipRestrictionsIsActivated,
            ipAdressSubnetMaskMap: ipRestrictionsSaved,
        };

        await updateIpRestrictions({
            orgId: currentCenter.id,
            data,
        });

        setIpRestrictionsIsActivated(data.enabled);
    };

    useEffect(() => {
        const loadPasswordPolicyData = async () => {
            const data = await getPasswordPolicyForDispatchCenter(
                currentCenter.id
            );
            if (data) {
                if (data.enabledPolicies.length !== 0) {
                    data.enabledPolicies.forEach(policy => {
                        setPolicies(policy);
                    });
                }
                setPasswordMinimumLength(data.minimumPasswordLength);
                setPasswordExpirationDate(data.passwordExpirationLength);
            }
        };

        const fetchAzureSystemStatus = async () => {
            setIsAzureSystem(await getAzureStatus());
        };

        const fetchIpRestrictions = async () => {
            const response = await getIpRestrictionsByOrg({
                orgId: currentCenter.id,
            });

            if (response) {
                setIpRestrictionsIsActivated(response.enabled);
                setIpRestrictionsSaved(response.ipAdressSubnetMaskMap);
            }
        };

        fetchAzureSystemStatus();
        loadPasswordPolicyData();
        fetchIpRestrictions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="center-authentication">
            {!isAzureSystem ? (
                <form className="authentication-form">
                    <h2>{replaceText(texts, 'password.policy.header')}</h2>
                    <p>{replaceText(texts, 'password.policy.description')}</p>
                    <div className="authentication-form__row">
                        <div className="flex">
                            <h3>
                                {replaceText(
                                    texts,
                                    'password.requirement.header'
                                )}
                            </h3>
                            <div>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={hasMinimumLength}
                                        onChange={() => {}}
                                        onClick={() => {
                                            setHasMinimumLength(
                                                !hasMinimumLength
                                            );
                                        }}></input>
                                    {replaceText(
                                        texts,
                                        'password.requirement.minLength'
                                    )}
                                </label>
                                {hasMinimumLength ? (
                                    <input
                                        type="number"
                                        value={passwordMinimumLength}
                                        onChange={
                                            validateMinimumLength
                                        }></input>
                                ) : (
                                    ''
                                )}
                            </div>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={hasMixedCase}
                                    onChange={() => {}}
                                    onClick={() => {
                                        setHasMixedCase(!hasMixedCase);
                                    }}></input>
                                {replaceText(
                                    texts,
                                    'password.requirement.mixedCase'
                                )}
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={hasSpecialCharacters}
                                    onChange={() => {}}
                                    onClick={() => {
                                        setHasSpecialCharacters(
                                            !hasSpecialCharacters
                                        );
                                    }}></input>
                                {replaceText(
                                    texts,
                                    'password.requirement.specialChars'
                                )}
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={hasNumerics}
                                    onChange={() => {}}
                                    onClick={() => {
                                        setHasNumerics(!hasNumerics);
                                    }}></input>
                                {replaceText(
                                    texts,
                                    'password.requirement.numerical'
                                )}
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={compareLastPassword}
                                    onChange={() => {}}
                                    onClick={() => {
                                        setCompareLastPassword(
                                            !compareLastPassword
                                        );
                                    }}></input>
                                {replaceText(
                                    texts,
                                    'password.requirement.lastPassword'
                                )}
                            </label>
                            <button
                                id="save-password-policy-button"
                                className="btn btn--primary"
                                onClick={savePolicies}
                                disabled={policiesSaved}>
                                {replaceText(texts, 'password.button.save')}
                            </button>
                            <div
                                className={`success-message ${
                                    policiesSaved
                                        ? 'success-message--isShown'
                                        : ''
                                }`}>
                                <span>
                                    {replaceText(texts, 'settings.saved')}
                                </span>
                            </div>
                        </div>
                        <div className="flex">
                            <h3>
                                {replaceText(
                                    texts,
                                    'password.requirement.expiry'
                                )}
                            </h3>
                            <label>
                                <input
                                    type="number"
                                    value={passwordExpirationDate}
                                    onChange={validatePasswordExpiry}
                                    max="99"></input>
                                {replaceText(texts, 'time.mon')}
                            </label>
                            <span>
                                {replaceText(
                                    texts,
                                    'password.requirement.expiryDescription'
                                )}
                            </span>
                        </div>
                    </div>
                    <div className="center-authentication__row" />
                </form>
            ) : (
                <></>
            )}
            <h2>{replaceText(texts, 'token.generate')}</h2>
            <p>{replaceText(texts, 'token.hint')}</p>
            <div className="center-authentication__generate">
                <button
                    onClick={addApiUser}
                    className="btn--primary"
                    disabled={userOrgId !== orgId}>
                    {replaceText(texts, 'token.generation')}
                </button>
                {userOrgId !== orgId && (
                    <span>{replaceText(texts, 'token.only')}</span>
                )}
            </div>
            <div className="center-authentication__token">
                <textarea
                    ref={areaRef}
                    placeholder="generiertes Token"
                    value={apiToken}
                    disabled={userOrgId !== orgId}
                    readOnly></textarea>
                {apiToken !== '' && (
                    <button
                        className="btn btn--primary"
                        onClick={copyToClipboard}>
                        {replaceText(texts, 'token.copy')}
                    </button>
                )}
            </div>
            {apiToken !== '' && (
                <div className="center-authentication__info">
                    <p>
                        <span>{replaceText(texts, 'token.created')}</span> {iat}
                    </p>
                    <p>
                        <span>{replaceText(texts, 'token.valid')}</span> {exp}
                    </p>
                </div>
            )}
            <div className="center-authentication__row" />
            <div className={ipRestrictionClasses}>
                <label htmlFor="ip">
                    <h2>{replaceText(texts, 'ip.restriction')}</h2>
                </label>
                <div className="centerForm__row">
                    <div className="ip-restriction__sub-container">
                        <ToggleSwitch
                            isActive={ipRestrictionsIsActivated}
                            label={replaceText(texts, 'ip.restrictions.toggle')}
                            changeHandler={() => {
                                toggleIpRestrictions();
                            }}
                        />
                        <div className="ip-restriction__input-container">
                            <div className="ip-restriction__edit-field">
                                <span>
                                    {replaceText(
                                        texts,
                                        'ip.restrictions.address'
                                    )}
                                </span>
                                <input
                                    className="wide"
                                    placeholder={replaceText(
                                        texts,
                                        'ip.restrictions.addressPlaceholder'
                                    )}
                                    type="text"
                                    onChange={e => updateIpAddress(e)}
                                    value={ipAddressInput}></input>
                            </div>
                            <span>/</span>
                            <div className="ip-restriction__edit-field">
                                <span>
                                    {replaceText(
                                        texts,
                                        'ip.restrictions.subnetMask'
                                    )}
                                </span>
                                <input
                                    className="narrow"
                                    placeholder={replaceText(
                                        texts,
                                        'ip.restrictions.subnetPlaceholder'
                                    )}
                                    type="text"
                                    onChange={e => updateSubnetMask(e)}
                                    value={subnetMaskInput}></input>
                            </div>
                            <div
                                className={addNewAddressClasses}
                                onClick={() => {
                                    saveIpRestriction();
                                }}>
                                <Add />
                            </div>
                        </div>
                        <span className="error-message">
                            {replaceText(texts, 'ip.error')}
                        </span>
                        <div className="ip-restriction__delete-container">
                            <ul>
                                {Object.entries(ipRestrictionsSaved).map(
                                    (entry, key) => {
                                        const address = entry[0];
                                        const mask = entry[1];
                                        return (
                                            <li key={key}>
                                                <div className="ip-restriction__address-entry">
                                                    <label className="wide">
                                                        <span>{address}</span>
                                                    </label>
                                                    <span>/</span>
                                                    <label className="narrow">
                                                        <span>
                                                            {mask as any}
                                                        </span>
                                                    </label>
                                                    <div
                                                        onClick={() => {
                                                            deleteAddress(key);
                                                        }}>
                                                        <Remove />
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    }
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Map Redux State To Props
const mapStateToProps = (state: Props) => {
    return {
        apiToken: state.apiToken,
        currentUserOrganization: state.currentUser.organization,
        texts: state.texts.texts,
        centers: state.dispatchCenters.centers,
    };
};

// Connect Props to Component
const connector = connect(mapStateToProps);
export default connector(CenterAuthenticationComponent);
